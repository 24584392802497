import React, { useState, useEffect } from "react";
import "./index.scss";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import CancelIcon from "@mui/icons-material/Cancel";
import { DownloadModal } from "../customerModals";
import Stack from "@mui/material/Stack";
import { getHeaderConfig } from "./config";
import StepLabel from "@mui/material/StepLabel";
import { invokeApi, HTTP_METHODS, plainApi } from "../../utils/http-service";
import { userRedirectionRoute } from "../../../common-utilities/utils/end-points/cf-approval";
import {
  BD_LEAD_HOSTNAME,
  HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import CustomModal from "../../../common-utilities/core/modal";
import { setOfflineData, getOfflineData } from "../../utils/offline-services";
import Details from "./details";
import Doc from "./approver";
import Approver from "./document";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import Details2 from "./details2";
import Snapshots from "./snapshots";
import {
  allStores,
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
  userTypes,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { FormControlLabel, Checkbox } from "@mui/material";
import { pick } from "../../../common-utilities/utils";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";

import {
  clearProperty,
  deleteObjectProperties,
  setProperty,
} from "../../../common-utilities/utils/misc";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import FieldAutoComplete from "./auto-complete";
import { downloadSumryRept } from "./report-formatter";
import { defaultFltrDates } from "../../utils";
import { businessDetailsValidation } from "./utils";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";

const Document = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const role = getOfflineData("user")?.role;
    if (role !== userTypes.admin) {
      navigate(userRedirectionRoute);
    }
  }, [navigate]);
  const storeList = allStores.map((item) => {
    return { label: item, value: item };
  });
  const [fiveyearplan, setfiveyearplan] = useState(null);
  const [approver1, setApprover1] = useState("");
  const [approver2, setApprover2] = useState("");
  const [approver3, setApprover3] = useState("");
  const [approver4, setApprover4] = useState("");
  const [approver5, setApprover5] = useState("");
  const [approver6, setApprover6] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);
  const [storeDetails, setStoreDetails] = useState([]);
  const [newStoreDetailsExcel, setnewStoreDetailsExcel] = useState(null);
  const [geoIqSiteReport, setGeoIqSiteReport] = useState(null);
  const [approvalEmail, setApprovalEmail] = useState(null);
  const [documentsList, setDocumentsList] = useState({});
  const defaultDates = defaultFltrDates();
  const [dateRange, setDateRange] = useState({
    startDate: defaultDates[0],
    endDate: defaultDates[1],
  });
  const [userList, setUserList] = useState([]);
  const [fileName, setFileName] = useState({
    fiveYearPlan: "",
    newStoreDetailsPPT: "",
    newStoreDetailsExcel: "",
    approvalEmail: "",
    geoIqSiteReport: "",
  });
  const [newStoreDetailsPPT, setnewStoreDetailsPPT] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [createDocument, setCreateDocument] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [tableItems, setTableItems] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [id, setId] = useState(null);
  const [searchResId, setSearchResId] = useState("");
  const [snapShotFileName, setSnapShotFileName] = useState({
    unitEconomicModel: "",
    competitionAndNearbyRestaurantSales: "",
    gapmapSnapshot: "",
  });

  const [
    filterParamsAfterClickingOnApplyButton,
    setFilterParamsAfterClickingOnApplyButton,
  ] = useState({
    startDate: defaultDates[0],
    endDate: defaultDates[1],
    searchResId: "",
  });

  const [unitEconomicModel, setUnitEconomicModel] = useState(null);
  const [
    competitionAndNearbyRestaurantSales,
    setCompetitionAndNearbyRestaurantSales,
  ] = useState(null);
  const [gapmapSnapshot, setGapmapSnapshot] = useState(null);

  const [documentData, setDocumentData] = useState({
    cluster: "",
    format: "",
    BDPOC: "",
    ProjectsPOC: "",
    LegalPOC: "",
    ads: "",
    occupancyPercentage: "",
    deliveryPercentage: "",
    labourPercentage: "",
    ebitdaPercentage: "",
    totalCapex: "",
    approver1: null,
    approver2: null,
    approver3: null,
    restaurantName: "",
    paybackInMonths: "",
    approverEmailSubject: "",
    restaurantId: "",
    storeType: null,
    ccEmails: [],
  });
  const [leadList, setLeadList] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [leadData, setLeadData] = useState([]);
  const data = getOfflineData("user");
  const steps = ["Details", "Details2", "Documents", "Snapshots", "Approvers"];
  useEffect(() => {
    if (data.changePasswordOnFirstLogin === true) {
      setShowChangePasswordModal(true);
      setId(data.id);
    }
  }, []);
  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.LIST_ALL_USERS}`
    )
      .then((response) => {
        let finalEmailsList = [];
        let finaluserList = [];
        if (response) {
          finaluserList = response.map((user) => ({
            label: user.name,
            value: user.id,
          }));
        }
        setUserList(finaluserList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const loadData = (loadfilter, useFiltersAfterClickingOnApplyButton) => {
    const filter =
      typeof loadfilter === "object" ? { ...loadfilter } : { ...filters };
    let params = {
      ...filter,
    };
    if (!filter.page) {
      filter.page = filters.page;
    }
    if (!filter.limit) {
      filter.limit = filters.limit;
    }
    if (!filter.sortBy) {
      filter.sortBy = filters.sortBy;
    }
    if (useFiltersAfterClickingOnApplyButton) {
      deleteObjectProperties(params, [
        "startDate",
        "endDate",
        "restaurantId",
        "storeType",
        "showOnlyApproved",
        "restaurantName",
      ]);
      const newParams = pick(filterParamsAfterClickingOnApplyButton, [
        "storeType",
        "showOnlyApproved",
        "restaurantName",
      ]);

      params = {
        ...params,
        ...newParams,
      };

      if (filterParamsAfterClickingOnApplyButton.startDate) {
        params.startDate = filterParamsAfterClickingOnApplyButton.startDate;
      }

      if (filterParamsAfterClickingOnApplyButton.endDate) {
        params.endDate = filterParamsAfterClickingOnApplyButton.endDate;
      }
      if (filterParamsAfterClickingOnApplyButton.searchResId?.trim()) {
        params[
          "restaurantId"
        ] = `${filterParamsAfterClickingOnApplyButton.searchResId?.trim()}`;
      }
    } else {
      if (dateRange.startDate) {
        params.startDate = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.endDate = dateRange.endDate;
      }
      if (searchResId?.trim()) {
        params["restaurantId"] = `${searchResId}`;
      }
    }
    addCorrectStartEndDate(params);
    params.nofmtChg = true;
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.REPORTS}`, null, params)
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setDocumentsList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLeadList = () => {
    invokeApi(HTTP_METHODS.GET, `${BD_LEAD_HOSTNAME}${REST_URLS.GET_LEAD_LIST}`)
      .then((res) => {
        if (!res.message) {
          const result = res.map((ele) => ({
            label: `${ele?.id}-${ele?.locationInfo?.catchment || ""}-${
              ele?.locationInfo?.city || ""
            }`,
            value: ele?.id,
          }));
          setLeadList(result);
          setLeadData(res);
        }
      })
      .catch((err) => console.log(err));
  };
  const formSubmitHandler = () => {
    const storeType = documentData.storeType?.value;
    const state = documentData?.state?.value;
    const city = documentData?.city?.value;
    const cluster = documentData?.cluster?.value;
    let lead = documentData?.lead?.value;
    let restaurantIdMatch = false;
    if (documentData.storeType?.value === storeTypes.cocoAcquired) {
      restaurantIdMatch = !documentData.restaurantId;
    }
    const isCocoBuild = documentData?.storeType?.value === storeTypes.cocoBuild;
    const isCocoAcquired =
      documentData.storeType?.value === storeTypes.cocoAcquired;
    const { error: businnesDetailsErr, message: businessErrMsg } =
      businessDetailsValidation(isCocoAcquired, documentData);
    let isApprovalEmailRequired = false;
    if (isCocoAcquired) {
      const forward1YearEbitdaMultiple =
        documentData?.businessDetails?.forward1YearEbitdaMultiple?.split("")[0];
      const ttmAdjustedEbitdaMultiple =
        documentData?.businessDetails?.ttmAdjustedEbitdaMultiple?.split("")[0];
      if (
        parseInt(forward1YearEbitdaMultiple) > 5 ||
        parseInt(ttmAdjustedEbitdaMultiple) > 5
      ) {
        isApprovalEmailRequired = true;
      }
    }
    if (
      !fiveyearplan ||
      !newStoreDetailsPPT ||
      !unitEconomicModel ||
      !competitionAndNearbyRestaurantSales ||
      !gapmapSnapshot ||
      !documentData.BDPOC ||
      !documentData.LegalPOC ||
      !documentData.ProjectsPOC ||
      !documentData.approver1 ||
      !documentData.approver2 ||
      !documentData.approver3 ||
      !documentData.cluster ||
      !documentData.deliveryPercentage ||
      !documentData.ads ||
      !documentData.ebitdaPercentage ||
      !documentData.labourPercentage ||
      !documentData.format ||
      !documentData.occupancyPercentage ||
      !documentData.totalCapex ||
      !documentData.restaurantName ||
      !documentData.paybackInMonths ||
      !documentData.approverEmailSubject ||
      restaurantIdMatch ||
      (isCocoAcquired ? false : !newStoreDetailsExcel) ||
      (isCocoAcquired ? false : !geoIqSiteReport) ||
      !storeType ||
      !documentData.city ||
      !documentData.state ||
      businnesDetailsErr ||
      (isCocoBuild && !lead) ||
      (isApprovalEmailRequired && !approvalEmail)
    ) {
      toast.error(
        `${businessErrMsg ? businessErrMsg : "Empty field not allowed"}`,
        { autoClose: 2000 }
      );
      return;
    }
    const ccEmails = documentData.ccEmails.map((item) => {
      return item.value;
    });
    const payload = {
      ...documentData,
      storeType,
      city,
      cluster,
      state,
      ccEmails,
      lead,
    };
    if (!isCocoBuild) {
      delete payload?.lead;
    }
    if (documentData.storeType?.value !== storeTypes.cocoAcquired) {
      delete payload.restaurantId;
    }
    payload.uploadedDocuments = {
      fiveyearplan,
      newStoreDetailsPPT,
    };
    if (!isCocoAcquired) {
      payload.uploadedDocuments.newStoreDetailsExcel = newStoreDetailsExcel;
      payload.uploadedDocuments.geoIqSiteReport = geoIqSiteReport;
    }
    if (isCocoAcquired && approvalEmail) {
      payload.uploadedDocuments.approvalEmail = approvalEmail;
    }
    payload.snapShot = {
      unitEconomicModel,
      competitionAndNearbyRestaurantSales,
      gapmapSnapshot,
    };

    if (!isCocoAcquired) {
      deleteObjectProperties(payload, ["businessDetails"]);
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATE_DOCUMENT}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Document Created Successfully", { autoClose: 2000 });
          cancelHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const approver1Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver1: event.value,
    }));

    setApprover1(event.label);
  };
  const approver2Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver2: event.value,
    }));

    setApprover2(event.label);
  };
  const approver3Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver3: event.value,
    }));

    setApprover3(event.label);
  };
  const approver4Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver4: event.value,
    }));

    setApprover4(event.label);
  };
  const approver5Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver5: event.value,
    }));

    setApprover5(event.label);
  };
  const approver6Handler = (event) => {
    setDocumentData((prevVal) => ({
      ...prevVal,
      approver6: event.value,
    }));

    setApprover6(event.label);
  };
  const deleteFile = (title) => {
    if (title === "Five Year Plan") {
      setFileName((prevVal) => ({
        ...prevVal,
        fiveYearPlan: "",
      }));
      setfiveyearplan(null);
    }
    if (title === "New Store Details Excel") {
      setFileName((prevVal) => ({
        ...prevVal,
        newStoreDetailsExcel: "",
      }));
      setnewStoreDetailsExcel(null);
    }
    if (title === "GeoIQ site report") {
      setFileName((prevVal) => ({
        ...prevVal,
        geoIqSiteReport: "",
      }));
      setGeoIqSiteReport(null);
    }
    if (title === "Approval Email") {
      setFileName((prevVal) => ({
        ...prevVal,
        approvalEmail: "",
      }));
      setApprovalEmail(null);
    }
    if (title === "New Store Details") {
      setFileName((prevVal) => ({
        ...prevVal,
        newStoreDetailsPPT: "",
      }));
      setnewStoreDetailsPPT(null);
    }
  };
  const deleteSnapshotFile = (title) => {
    if (title === "Unit Economics Model - Cover") {
      setSnapShotFileName((prevVal) => ({
        ...prevVal,
        unitEconomicModel: "",
      }));
      setUnitEconomicModel(null);
    }
    if (
      title === cocoAcqKeyNames.competitionAndNearbyRestaurantSales ||
      title === cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
    ) {
      setSnapShotFileName((prevVal) => ({
        ...prevVal,
        competitionAndNearbyRestaurantSales: "",
      }));
      setCompetitionAndNearbyRestaurantSales(null);
    }
    if (title === "Total Acquisition Cost") {
      setSnapShotFileName((prevVal) => ({
        ...prevVal,
        gapmapSnapshot: "",
      }));
      setGapmapSnapshot(null);
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const setPasswordHandler = () => {
    const payload = {
      password: password,
    };
    if (password !== confirmPassword) {
      toast.error("Invalid Password", { autoClose: 2000 });
    } else {
      invokeApi(
        HTTP_METHODS.PATCH,
        `${HOSTNAME}${REST_URLS.EDIT_USER}${id}`,
        payload
      )
        .then((res) => {
          if (res.changePasswordOnFirstLogin === false) {
            toast.success("Password Set successfully", { autoClose: 2000 });
            setOfflineData("user", res);
          }
          setShowChangePasswordModal(false);
          setId(false);
          setPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          toast.error(err, { autoClose: 2000 });
        });
    }
    setShowChangePasswordModal(false);
  };

  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        {
          storeNumber: searchTerm,
        }
      ).then((res) => {
        const storeList = res?.results?.map((ele) => ele?.storeNumber);
        setStoreOptions(storeList);
        setStoreDetails(res?.results);
      });
    }, 300);
  };

  const inputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
      setSearchResId(value);
    }
    if (!value) {
      setSearchResId("");
      setStoreOptions([]);
    }
  };
  const onClickShowDataFunction = (data) => {
    if (data) {
      const isCocoAcquired = data?.storeType === storeTypes.cocoAcquired;
      const oddLabel = isCocoAcquired ? "ODD" : "Site approval pack";
      let finalData = [
        { label: "Unit Economics Model", value: "" },
        { label: oddLabel, value: "" },
      ];
      const { uploadedDocuments, snapShot } = data;
      if (uploadedDocuments) {
        const {
          fiveyearplan,
          newStoreDetailsPPT,
          newStoreDetailsExcel,
          approvalEmail,
          geoIqSiteReport,
        } = uploadedDocuments;
        finalData[0].value = fiveyearplan;
        finalData[1].value = newStoreDetailsPPT;
        if (!isCocoAcquired) {
          finalData.push({
            label: "New Store Details (excel)",
            value: newStoreDetailsExcel,
          });
          finalData.push({
            label: "GeoIQ site report",
            value: geoIqSiteReport,
          });
        }
        if (isCocoAcquired && approvalEmail) {
          finalData.push({
            label: "Approval Email",
            value: approvalEmail,
          });
        }
      }
      if (snapShot) {
        const {
          unitEconomicModel,
          competitionAndNearbyRestaurantSales,
          gapmapSnapshot,
        } = snapShot;

        finalData = [
          ...finalData,
          { label: "Unit Economics Model UEM Cover", value: unitEconomicModel },
          {
            label: isCocoAcquired
              ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
              : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales,
            value: competitionAndNearbyRestaurantSales,
          },
          {
            label: `${
              isCocoAcquired ? "Total Acquisition Cost" : "Gapmap snapshot"
            }`,
            value: gapmapSnapshot,
          },
        ];
      }
      setTableItems(finalData);
    }
  };
  const downloadReport = async () => {
    const filter = { ...filters };
    let params = {
      ...filter,
    };
    params.page = 1;
    params.limit = documentsList.totalResults;
    deleteObjectProperties(params, [
      "storeType",
      "showOnlyApproved",
      "restaurantName",
    ]);
    const newParams = pick(filterParamsAfterClickingOnApplyButton, [
      "storeType",
      "showOnlyApproved",
      "restaurantName",
    ]);

    params = {
      ...params,
      ...newParams,
    };

    if (filterParamsAfterClickingOnApplyButton.startDate) {
      params.startDate = filterParamsAfterClickingOnApplyButton.startDate;
    } else {
      delete params.startDate;
    }

    if (filterParamsAfterClickingOnApplyButton.endDate) {
      params.endDate = filterParamsAfterClickingOnApplyButton.endDate;
    } else {
      delete params.endDate;
    }
    if (filterParamsAfterClickingOnApplyButton.searchResId?.trim()) {
      params[
        "restaurantId"
      ] = `${filterParamsAfterClickingOnApplyButton.searchResId?.trim()}`;
    } else {
      delete params.searchResId;
    }
    addCorrectStartEndDate(params);
    params.nofmtChg = true;

    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.REPORTS}`, null, params)
      .then((response) => {
        if (!response.message) {
          downloadSumryRept(response?.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cancelHandler = () => {
    setCreateDocument(false);
    handleReset();
    setDocumentData((prevVal) => ({
      ...prevVal,
      restaurantId: null,
      cluster: "",
      format: "",
      BDPOC: "",
      ProjectsPOC: "",
      LegalPOC: "",
      ads: "",
      occupancyPercentage: "",
      deliveryPercentage: "",
      labourPercentage: "",
      ebitdaPercentage: "",
      totalCapex: "",
      approver1: null,
      approver2: null,
      approver3: null,
      restaurantName: "",
      paybackInMonths: "",
      approverEmailSubject: "",
      restaurantId: "",
      storeType: null,
      ccEmails: [],
      state: null,
      city: null,
      lead: null,
    }));
    setApprover1("");
    setApprover2("");
    setApprover3("");
    setFileName((prevVal) => ({
      ...prevVal,
      fiveYearPlan: "",
      newStoreDetailsPPT: "",
      newStoreDetailsExcel: "",
      approvalEmail: "",
    }));
    setSnapShotFileName((prevVal) => ({
      ...prevVal,
      unitEconomicModel: "",
      competitionAndNearbyRestaurantSales: "",
      gapmapSnapshot: "",
    }));
    setfiveyearplan(null);
    setnewStoreDetailsPPT(null);
    setnewStoreDetailsExcel(null);
    setGeoIqSiteReport(null);
    setApprovalEmail(null);
    setCompetitionAndNearbyRestaurantSales(null);
    setGapmapSnapshot(null);
    setUnitEconomicModel(null);
  };
  useEffect(() => {
    if (documentData?.storeType?.value === storeTypes.cocoBuild) {
      getLeadList();
    }
  }, [documentData?.storeType]);
  useEffect(() => {
    if (data.changePasswordOnFirstLogin === true) {
      setShowChangePasswordModal(true);
      setId(data.id);
    }
  }, []);
  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.LIST_ALL_USERS}`
    )
      .then((response) => {
        let finalEmailsList = [];
        let finaluserList = [];
        if (response) {
          finaluserList = response.map((user) => ({
            label: user.name,
            value: user.id,
          }));
        }
        setUserList(finaluserList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const role = getOfflineData("user")?.role;
    if (role !== userTypes.admin) {
      navigate(userRedirectionRoute);
    }
  }, [navigate]);
  return (
    <div>
      {!createDocument && (
        <div>
          <div className="document-table">
            <div className="mainDocHeader">
              <div className="docdateAndSelectContainer">
                <DateRangePicker
                  cleanable={false}
                  placeholder="Select Date Range"
                  onChange={dateRangeHandler}
                  value={[
                    dateRange.startDate ? new Date(dateRange.startDate) : null,
                    dateRange.endDate ? new Date(dateRange.endDate) : null,
                  ]}
                />
                <AutocompleteComponent
                  label="Search Restaurant Id"
                  onInputChange={inputChange}
                  noValue={true}
                  options={storeOptions}
                />

                <AutocompleteComponent
                  label="Select Type"
                  options={storeList}
                  onChange={(e, value) => {
                    if (value) {
                      setProperty("storeType", value?.value, setFilters);
                      setProperty("page", 1, setFilters);
                      loadData({
                        ...filters,
                        page: 1,
                        storeType: value?.value,
                      });
                    } else {
                      clearProperty("storeType", setFilters);
                    }
                  }}
                  fullWidth={true}
                  value={storeList.find(
                    (item) => item.value === filters.storeType
                  )}
                />

                <FieldAutoComplete
                  fieldName="restaurantName"
                  label="Select Restaurant Name"
                  setDataFunc={setFilters}
                  keyName="restaurantName"
                  filter={{ storeType: filters.storeType }}
                />
              </div>

              <div className="end-filters">
                <FormControlLabel
                  value="start"
                  checked={filters?.showOnlyApproved || false}
                  className="approvalLabel"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={() => {
                        setFilters((prevFilters) => {
                          if (prevFilters.showOnlyApproved) {
                            delete prevFilters.showOnlyApproved;
                          } else {
                            prevFilters.showOnlyApproved = true;
                          }
                          return { ...prevFilters };
                        });
                      }}
                    />
                  }
                  label="All Approved"
                  labelPlacement="start"
                />

                <div>
                  {(documentsList?.results || []).length > 0 && (
                    <DownloadBtn
                      onClick={() => {
                        downloadReport();
                      }}
                    />
                  )}
                </div>
                <div>
                  <CommonBtn
                    onClick={() => {
                      if (!filters.storeType) {
                        toast.error("Please select type", { autoClose: 2000 });
                        return;
                      }
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        page: 1,
                      }));
                      loadData({ ...filters, page: 1 });
                      let newFilters = pick(filters, [
                        "showOnlyApproved",
                        "storeType",
                        "restaurantName",
                      ]);
                      newFilters = {
                        ...newFilters,
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                        searchResId,
                      };
                      setFilterParamsAfterClickingOnApplyButton(newFilters);
                    }}
                    sx={{ cursor: "pointer" }}
                    text="Apply"
                  />
                </div>
                <div className="createDocButton">
                  <CommonBtn
                    variant="contained"
                    className="button"
                    size="medium"
                    color="primary"
                    onClick={() => setCreateDocument(true)}
                    text="Create Document"
                  />
                </div>
              </div>
            </div>
            {filters?.storeType && (
              <MuiTable
                columnsList={getHeaderConfig(loadData)}
                dataList={documentsList?.results || []}
                filters={filters}
                pageCount={documentsList?.totalPages}
                onClick={onClickShowDataFunction}
                onChange={(page) => {
                  setFilters({
                    ...filters,
                    page,
                  });
                  loadData({ limit: filters.limit, page }, true);
                }}
              ></MuiTable>
            )}

            {tableItems && (
              <DownloadModal
                title="Uploaded files"
                closeModal={setTableItems}
                data={tableItems}
                tableClassName
              />
            )}
          </div>
        </div>
      )}

      {createDocument && (
        <div>
          <div className="cancel-div">
            <CancelIcon
              onClick={cancelHandler}
              sx={{ cursor: "pointer" }}
              color="error"
              fontSize="large"
            />
          </div>
          <div className="doc-container">
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={activeStep}
                orientation={
                  window.innerWidth <= 599 ? "vertical" : "horizontal"
                }
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <>
                {activeStep === 0 && (
                  <Details
                    setDocumentData={setDocumentData}
                    documentData={documentData}
                    setApprover4={setApprover4}
                    setApprover5={setApprover5}
                    setApprover6={setApprover6}
                    leadList={leadList}
                    leadData={leadData}
                  />
                )}
                {activeStep === 1 && (
                  <Details2
                    setDocumentData={setDocumentData}
                    documentData={documentData}
                  />
                )}
                {activeStep === 4 && (
                  <Doc
                    approver1={approver1}
                    userList={userList}
                    approver1Handler={approver1Handler}
                    approver2={approver2}
                    approver2Handler={approver2Handler}
                    approver3={approver3}
                    approver3Handler={approver3Handler}
                    setDocumentData={setDocumentData}
                    documentData={documentData}
                    approver4={approver4}
                    approver4Handler={approver4Handler}
                    approver5={approver5}
                    approver5Handler={approver5Handler}
                    approver6={approver6}
                    approver6Handler={approver6Handler}
                  />
                )}
                {activeStep === 3 && (
                  <Snapshots
                    snapShotFileName={snapShotFileName}
                    deleteSnapshotFile={deleteSnapshotFile}
                    setSnapShotFileName={setSnapShotFileName}
                    setUnitEconomicModel={setUnitEconomicModel}
                    setCompetitionAndNearbyRestaurantSales={
                      setCompetitionAndNearbyRestaurantSales
                    }
                    setGapmapSnapshot={setGapmapSnapshot}
                    documentData={documentData}
                  />
                )}
                {activeStep === 2 && (
                  <Approver
                    fileName={fileName}
                    deleteFile={deleteFile}
                    setFileName={setFileName}
                    setfiveyearplan={setfiveyearplan}
                    setnewStoreDetailsPPT={setnewStoreDetailsPPT}
                    documentData={documentData}
                    setnewStoreDetailsExcel={setnewStoreDetailsExcel}
                    setApprovalEmail={setApprovalEmail}
                    setGeoIqSiteReport={setGeoIqSiteReport}
                  />
                )}
                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 5 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonBtn
                      color={activeStep === 0 ? "inherit" : "primary"}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      variant="contained"
                      text="Back"
                      size="medium"
                    />
                  </Box>
                  <Box sx={{ flex: "1 1 auto", mb: 2 }} />
                  {activeStep === steps.length - 1 && (
                    <CommonBtn
                      onClick={formSubmitHandler}
                      variant="contained"
                      color="primary"
                      size="medium"
                      showSaveIcon={true}
                    />
                  )}
                  {activeStep !== steps.length - 1 && (
                    <CommonBtn
                      onClick={handleNext}
                      className="outlined-btn"
                      variant="outlined"
                      text="Next"
                      size="medium"
                    />
                  )}
                </Box>
              </>
            </Box>
          </div>
        </div>
      )}

      {showChangePasswordModal && (
        <CustomModal
          title="Change Password"
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setShowChangePasswordModal(false);
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="password"
              type="password"
              style={{ marginBottom: "2em" }}
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="outlined"
              className="new-textfield"
              value={password}
              size="small"
            />
            <TextField
              fullWidth
              id="confirmPassword"
              style={{ marginBottom: "1em" }}
              label="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              className="new-textfield"
              variant="outlined"
              value={confirmPassword}
              type="password"
              size="small"
            />

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <CommonBtn
                variant="contained"
                color="primary"
                onClick={setPasswordHandler}
                fullWidth
                text="Confirm"
                size="small"
              />

              <CommonBtn
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setShowChangePasswordModal(false);
                }}
                fullWidth
                text="Cancel"
                size="small"
              />
            </Stack>
          </Box>
        </CustomModal>
      )}
    </div>
  );
};

export default Document;
