/** @format */
const commonPrefix = "/v1/fininv-service/";
const intranetService = `/v1/central-service/`;
export const VENDOR_ONBOARD_REST_URLS = {
  LOGIN: `${commonPrefix}auth/login`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGOUT: `${commonPrefix}auth/logout`,
  VENDOR_ONBOARD: `${commonPrefix}vendor-onboard`,
  VENDOR_FORMS_ACTIONS: `${commonPrefix}vendor-forms/action`,
  VENDOR_FORMS: `${commonPrefix}vendor-forms`,
  GET_VENDOR_FORM_BY_TOKEN: `${commonPrefix}vendor-forms/verify`,
  VENDOR_FORM_SUBMIT_BY_TOKEN: `${commonPrefix}vendor-forms/submit-form`,
  VENDOR: `${commonPrefix}vendor`,
  ADD_ATTACHMENTS_TO_FORM: `${commonPrefix}vendor-forms/attachments`,
  GET_DEPARTMENTS: `${intranetService}department/all-department-list`,
  RESEND_FORM: `${commonPrefix}vendor-forms/resend`,
  GET_VENDOR_BY_FORM_ID: `${commonPrefix}vendor-onboard/form/`,
  CREATE_VENDOR: `${commonPrefix}vendor/create-vendor`,
  UPDATE_VENDOR: `${commonPrefix}vendor/update-vendor`,
};

export const redirectToRoute = "/invoice-tracking";
