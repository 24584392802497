import { getOfflineData } from "./offline-services";

// Function for checking that current user logged in has right
// to approve the configuration depending on its additional role
// and stage's allowed role
// True means we show Approve/ Reject Button, otherwise they are disabled
export const checkIsApprover = approverDetails => {
  const user = getOfflineData("user");
  const userId = parseInt(user?.id || user?._id);
  const pendingWith = parseInt(approverDetails?.approver);

  return userId === pendingWith;
};
