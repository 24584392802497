import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import ItemList from "./item-list";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const MasterData = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="rm-pt budget-list">
      <div className="tabs-section">
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange}>
            <Tab label="Items" />
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <ItemList />
      </TabPanel>
    </div>
  );
};

export default MasterData;
