import { getLabelsAndValuesForSelect } from "../../common-utilities/utils";

export const validCocoBuiltLicenses = {
  fssai: "FSSAI",
  fire: "Fire NOC",
  changeOfUser: "Change of User",
  trade: "Trade",
  shopEstablishment: "Shop Establishment",
  dgLicence: "DG License",
  weightAndMeasurement: "Weight and Measurement",
  cto: "CTO",
  cte: "CTE",
  signage: "Signage",
  ppliceNOC: "Police NOC",
  others: "Others",
};

export const preAgreementDocsLabelsAndValues = {
  // commercialTermSheet: "Commercial Term Sheet",
  projectFeasibility: "Project Feasibility",
  LOI: "LOI",
  titleDeligenceReport: "Title Deligence Report",
  licenseFeasibility: "License Feasibility",
  possessionLetter: "Possession Letter",
};

export const agreementAndPostAgreementDocsLabelsAndValues = {
  // bta: "BTA",
  // franchiseTermination: "Franchise Termination",
  leaseAgreement: "Lease Agreement",
  registration: "Registration",
  submissionOfRegHardCopy: "Submisssion of Reg Hard copy",
  optionAgreement: "Option Agreement",
};
export const mfaRiderSubCategories = {
  rider: "Riders",
};
export const nocForGstSubCategories = {
  nocForGst: "NOC For GST",
};
export const ElectricityBillInNameOfLandloadSubCategories = {
  electricityBillInNameOfLandload: "Electricity Bill In Name Of Landload",
};
export const getrsecDepositInMonthsSelectList = () => {
  let numbericSelectValues = new Array(11)
    .fill(0)
    .map((_, index) => `${index + 2}`);
  const allrsecDepositValuesList = [...numbericSelectValues, "Lumpsum"];
  const allrsecDepositValuesSelectList = getLabelsAndValuesForSelect(
    allrsecDepositValuesList,
    allrsecDepositValuesList
  );
  return allrsecDepositValuesSelectList;
};

export const getRentRevShareDeliveryExcludesAggregatorsCommissionSelectList =
  () => {
    const allValues = ["Yes", "No"];
    return getLabelsAndValuesForSelect(allValues, allValues);
  };

export const mfaRidersSubCategoriesList = Object.values(mfaRiderSubCategories);
export const nocForGstSubCategoriesList = Object.values(nocForGstSubCategories);
export const electricityBillInNameOfLandloadSubCategoriesList = Object.values(
  ElectricityBillInNameOfLandloadSubCategories
);
