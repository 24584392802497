import { getLabelsAndValuesForSelect } from "../../common-utilities/utils";

export const validFOFOLicenses = {
  dgLicence: "DG License",
  weightAndMeasurement: "Weight and Measurement",
  cto: "CTO",
  cte: "CTE",
  signage: "Signage",
  ppliceNOC: "Police NOC",
  others: "Others",
};
