import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  createPayloadMapper,
  getAdvanceAmount,
  mapPoFileUploadResponse,
} from "./helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import "./index.scss";
import { AddCircle, ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddItemModal from "./add-item-modal";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import { getOfflineData } from "../../../utils/offline-services";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import DownloadTemplateButton from "../../master-data/common-components/upload-template";
import { uploadTemplates } from "../../master-data/constants";
import {
  getPOAmount,
  getPOLineItemAmount,
  createPOavailableBudgetAmount,
  getPOAmountWithoutTax,
  PO_ITEM_MODEL,
} from "../helper";
import CustomModal from "../../../../common-utilities/core/modal";
import POInfo from "./po-info";
import { setProperty } from "../../../../common-utilities/utils/misc";
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from "../../../utils/attachments";

const DEFAULT_COMPANY = "Eversub India Private Limited";

const CreatePO = ({}) => {
  const navigate = useNavigate();
  const userDetails = getOfflineData("user");
  const { department } = userDetails;
  const { budgetId } = useParams();
  const [searchParams] = useSearchParams();
  const poType = searchParams.get("poType");
  const lineItemIds = searchParams.get("lineItemIds");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [poItems, setPoItems] = useState({});
  const [vendors, setVendors] = useState([]);
  const [budgetDetails, setBudgetDetails] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [customSites, setCustomSites] = useState([]);
  const [isItemsUploaded, setItemsUploaded] = useState(false);
  const [createdPoDetails, setCreatedPoDetails] = useState(null);
  const [config, setConfig] = useState({
    isItemDeleted: false,
  });

  const { vendor = {} } = formDetails;

  let selectedLineItemIds = [];

  if (lineItemIds) {
    selectedLineItemIds = lineItemIds.split(",");
  }

  const { site = "", siteName = "", lineItems = [], siteCode } = budgetDetails;

  useEffect(() => {
    loadData();
    loadCategoryDetails();    
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      {
        page: 1,
        limit: 1000,
        status: "approved",
        _id: budgetId,
        populateLocation: true,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          const site = response.results[0]?.site || {};
          setBudgetDetails({
            ...response.results[0],
            site: site.id,
            siteName: site.name,
            siteCode: site.code,
          });

          loadLocations((response.results[0] || {}).site);
          if (Object.keys(site).length) {
            const locationData = {
              id: site.id,
              restaurantName:
                site.storeType === "COCO BUILT"
                  ? `${site.code} | ${site.name} | ${site.address}`
                  : `${site.name} | ${site.address}`,
            };
            const locationName =
              site.storeType === "COCO BUILT"
                ? `${site.code} | ${site.name} | ${site.address}`
                : `${site.name} | ${site.address}`;
            setProperty(
              ["billingLocation", "deliveryLocation"],
              [locationData, locationData],
              setFormDetails
            );
            setProperty(
              ["billingLocation", "deliveryLocation"],
              [locationName, locationName],
              setAutocompleteInputValues
            );
          }
          const { lineItems = [] } = response.results[0];
          lineItems.forEach((lineItem) => {
            if (selectedLineItemIds.includes(lineItem.id)) {
              onRowAdd(lineItem);
              onRowAdd(lineItem);
            }
          });
        }
      })
      .catch((err) => {});
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.DEPARTMENTS}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setFormDetails((prevDetails) => ({
            ...prevDetails,
            department: response.find((r) => r._id === department) || {},
            poDate: formatDate(new Date(), DATE_FORMATS["YYYY-MM-DD"]),
            forex: 1,
          }));
          setDepartmentList(response || []);
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`,
      null,
      {
        page: 1,
        limit: 1000,
        additionalFields: "paymentTerms",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setVendors(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadLocations = (poSite) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response && response.results.length > 0) {
          setCustomSites(
            response.results.map((r) => {
              return {
                id: r.id,
                restaurantName:
                  r.storeType === "COCO BUILT"
                    ? `${r.code} | ${r.name} | ${r.address}`
                    : `${r.name} | ${r.address}`,
              };
            }) || []
          );
        }
      })
      .catch((err) => {});
  };

  const loadCategoryDetails = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setCompanyList(response.results || []);
          let selectedCompany =
            response.results.find((r) => r.name === DEFAULT_COMPANY) || {};
          setProperty(
            "company",
            selectedCompany.name,
            setAutocompleteInputValues
          );
          setProperty("company", selectedCompany, setFormDetails);
        }
      })
      .catch((err) => {});
  };

  const onSave = (params) => {
    let payload = createPayloadMapper(
      formDetails,
      poItems,
      budgetDetails,
      poType,
      attachments
    );
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      payload,
      params
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed Create purchase order ");
        } else {
          setCreatedPoDetails(response);
        }
      })
      .catch((err) => {});
  };

  const inputChange = (event) => {
    setFormDetails({ ...formDetails, [event.target.name]: event.target.value });
  };

  const onDropDownChange = (key, value) => {
    let additionalDetails = {};
    switch (key) {
      case "vendor":
        additionalDetails.paymentTerms = `${value?.paymentTerms || ""}`;
        break;
      default:
        break;
    }

    setFormDetails({ ...formDetails, [key]: value, ...additionalDetails });
  };

  const updateItem = (item, index) => {
    poItems[item.lineItemId][index] = {
      ...poItems[index],
      ...item,
    };
    setPoItems({ ...poItems });
  };

  const onDeleteRow = (id, index) => {
    setProperty("isItemDeleted", true, setConfig);
    poItems[id].splice(index, 1);
    setPoItems({ ...poItems });
    setTimeout(() => {
      setProperty("isItemDeleted", false, setConfig);
    }, 200);
  };

  const onRowAdd = (lineItem) => {
    let items = poItems[lineItem.id] || [];
    poItems[lineItem.id] = [...items, PO_ITEM_MODEL];
    setPoItems({ ...poItems });
  };

  const getAddressList = () => {
    if (customSites.length > 0) {
      return customSites;
    } else {
      return [];
    }
  };

  const onLineItemsUpload = (response) => {
    const { data } = response;
    setPoItems(mapPoFileUploadResponse(data));
    setItemsUploaded(true);
  };

  const onDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const isDisabled = () => {
    return (
      !formDetails.vendor ||
      !formDetails.agreement ||
      !formDetails.deliveryLocation ||
      !formDetails.billingLocation
    );
  };

  return (
    <div className="create-po-container">
      <div className="po-create-header">
        <div className="left-container">
          <div className="back-title">
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=1");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className="title">Create PO</Typography>
          </div>
          <div className="info-section">
            <div className="info-box">
              <span>Site Name </span>
              <span>{siteName || ""}</span>
            </div>
            <div className="info-box">
              <span>Site Code </span>
              <span>{siteCode || ""}</span>
            </div>
            <div className="info-box">
              <span>PO Type </span>
              <span>{convertToCamelCaseFromUnderScore(poType)}</span>
            </div>
            <div className="info-box">
              <span>PO Amount </span>
              <span>{getPOAmount(poItems)}</span>
            </div>
            <div className="info-box">
              <span>PO Date </span>
              <span>{formatDate(new Date(), DATE_FORMATS["DD-MM-YYYY"])}</span>
            </div>
            <div className="info-box">
              <span>Available Budget Amt </span>
              <span>
                {createPOavailableBudgetAmount(lineItems, selectedLineItemIds)}
              </span>
            </div>
            {/* <div className='info-box'>
              <span>Base Amt</span>
              <span>{getPOAmountWithoutTax(poItems)}</span>
            </div>
            <div className='info-box'>
              <span>Tax Amt </span>
              <span>{getPOTaxAmount(poItems)}</span>
            </div>
            <div className='info-box'>
              <span>Total Amt </span>
              <span>{getPOAmount(poItems)}</span>
            </div> */}
          </div>
        </div>

        <div className="right-container">
          <Button
            variant="outlined"
            disabled={isDisabled()}
            onClick={() => {
              onSave();
            }}
          >
            Save as draft
          </Button>
          <Button
            variant="outlined"
            disabled={isDisabled()}
            onClick={() => {
              onSave({ createApproval: true });
            }}
          >
            Create order
          </Button>
        </div>
      </div>
      <div className="form-container">
        <div className="form-row">
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("company", val);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Company" />
            )}
            inputValue={autocompleteInputValues.company || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  company: newInputValue,
                });
              }
            }}
            getOptionLabel={(op) => op.name || ""}
            options={companyList}
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("vendor", val);
            }}
            getOptionLabel={(op) => `${op.code} - ${op.name}`}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Vendor *" />
            )}
            options={vendors}
          />
          <TextField
            value={vendor?.gst || ""}
            disabled
            size="small"
            label="Vendor GST No"
            fullWidth
          />
          <TextField
            value={vendor?.address || ""}
            disabled
            size="small"
            label="Vendor Address"
            fullWidth
          />

          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("agreement", val);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Agreement *" />
            )}
            options={["Yes", "No"]}
          />
        </div>
        <div className="form-row">
          <TextField
            value={formDetails.remarks || ""}
            onChange={inputChange}
            size="small"
            name="remarks"
            label="Agreement Remarks"
            fullWidth
          />

          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("deliveryLocation", val);
            }}
            value={formDetails.deliveryLocation || ""}
            inputValue={autocompleteInputValues.deliveryLocation || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  deliveryLocation: newInputValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Delivery Location *" />
            )}
            getOptionLabel={(op) => op.restaurantName || ""}
            options={getAddressList()}
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("billingLocation", val);
            }}
            value={formDetails.billingLocation || ""}
            inputValue={autocompleteInputValues.billingLocation || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  billingLocation: newInputValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Billing Location *" />
            )}
            getOptionLabel={(op) => op.restaurantName || ""}
            options={getAddressList()}
          />
          <TextField
            value={formDetails.paymentTerms || ""}
            onChange={inputChange}
            size="small"
            name="paymentTerms"
            label="Payment terms(Master vendor)"
            fullWidth
          />

          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("department", val);
            }}
            disabled
            getOptionLabel={(op) => op.name}
            value={formDetails.department}
            inputValue={
              (formDetails.department && formDetails.department.name) || ""
            }
            renderInput={(params) => (
              <TextField {...params} size="small" label="Department" />
            )}
            options={departmentList}
          />
        </div>
        <div className="form-row">
          <TextField
            value={formDetails.advancePercent}
            onChange={inputChange}
            size="small"
            type="number"
            name="advancePercent"
            label="Advance Percent"
            fullWidth
          />
          <TextField
            value={
              getAdvanceAmount(
                formDetails.advancePercent || 0,
                getPOAmountWithoutTax(poItems)
              ) || ""
            }
            onChange={inputChange}
            size="small"
            type="number"
            name="advanceAmount"
            label="Advance Amount"
            disabled
            fullWidth
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("currency", val);
            }}
            value={formDetails?.currency || null}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Currency" />
            )}
            options={currencyOptions}
          />
          <TextField
            value={formDetails.termsAndConditions || ""}
            onChange={inputChange}
            size="small"
            name="termsAndConditions"
            label="Terms and conditions"
            fullWidth
          />

          <TextField
            value={formDetails.forex || ""}
            onChange={inputChange}
            type="number"
            size="small"
            name="forex"
            label="Forex Rate"
            fullWidth
          />
        </div>
      </div>
      {vendor && vendor.code ? (
        <>
          {!isItemsUploaded && (
            <div className="po-attachment-form-container">
              <FileUpload
                uploadText="Upload PO Item Details"
                clsName="add-additional-doc"
                id="po-items-excel"
                url={`${FINANCE_HOSTNAME}${
                  FINANCE_REST_URLS.UPLOAD_MASTER_DATA
                }po-item-details?site=${site}&lineItemIds=${lineItemIds}&vendorCode=${
                  (vendor && vendor.code) || ""
                }`}
                callBack={onLineItemsUpload}
                formDataKey="file"
              />
              <DownloadTemplateButton type={uploadTemplates["po-items"]} />
            </div>
          )}
          <div className="po-additional-attachment-container">
            <FileUpload
              uploadText="Additional Documents"
              clsName="add-additional-doc"
              id="additional-documents"
              url={`${FINANCE_HOSTNAME}${
                FINANCE_REST_URLS.UPLOAD_DOCUMENT
              }${getUploadAttachmentQueryParams(
                { attachmentKey: "documentLinks", budgetId },
                uploadAttachmentTypes.createPo
              )}`}
              callBack={(response) => {
                const { data } = response;
                setAttachments([...attachments, data]);
              }}
              formDataKey="file"
            />
            {attachments.map((attachment, index) => {
              return (
                <Chip
                  label={attachment.originalName}
                  onDelete={() => onDelete(index)}
                  onClick={() => window.open(attachment?.docUrl, "_blank")}
                />
              );
            })}
          </div>
          <div className="po-add-items-container">
            <div className="added-po-items-list">
              {lineItems.map((lineItem) => {
                if (!selectedLineItemIds.includes(lineItem.id)) {
                  return;
                }
                return (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="po-accordion-summary"
                    >
                      <div className="accordion-header">
                        <div className="left-container">
                          <Typography>
                            {lineItem.name} (
                            {(poItems[lineItem.id] || []).length})
                          </Typography>

                          {formDetails.vendor && formDetails.vendor.id && (
                            <IconButton
                              disabled={
                                lineItem.availableAmount <= 0 ||
                                !(
                                  (formDetails.vendor &&
                                    formDetails.vendor.id) ||
                                  ""
                                )
                              }
                              onClick={(event) => {
                                onRowAdd(lineItem);
                                event.stopPropagation();
                              }}
                            >
                              <AddCircle color="primary" />
                            </IconButton>
                          )}
                        </div>
                        <div className="right-container">
                          <span>
                            Available Amount :{" "}
                            {Math.round(lineItem.availableAmount).toFixed(2)}
                          </span>
                          <span
                            className={
                              getPOLineItemAmount(poItems[lineItem.id] || []) >
                              lineItem.availableAmount
                                ? "error"
                                : ""
                            }
                          >
                            Order Amount :{" "}
                            {getPOLineItemAmount(poItems[lineItem.id] || [])}
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {(poItems[lineItem.id] || []).map((poItem, index) => {
                        return (
                          <AddItemModal
                            poItem={poItem}
                            lineItem={lineItem}
                            vendorCode={
                              formDetails.vendor && formDetails.vendor.code
                            }
                            isItemDeleted={config.isItemDeleted}
                            updateItem={(item) => updateItem(item, index)}
                            isItemsUploaded={isItemsUploaded}
                            onDelete={() => onDeleteRow(lineItem.id, index)}
                          />
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="po-attachment-form-container">
          <span>Please select vendor to add items</span>
        </div>
      )}
      {createdPoDetails && (
        <CustomModal
          title="PO Successfully created"
          disabledOutsideClick={true}
        >
          <POInfo
            poDetails={createdPoDetails}
            siteCode={siteCode}
            siteName={siteName}
            onOk={() => {
              navigate("/subway-finance?index=1");
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default CreatePO;
