import { agrAndPostAgrCocoAcqDateCheck } from "./validation-utils";
import { agreementAndPostAgreementDocsLabelsAndValues } from "../../../utils/coco-acquired.config";
export const agreementAndPostAgreementDocsCocoAcquired = (
  formDetails,
  docsCheck
) => {
  let monthlyRentMgStartYearCheck = false;
  let monthlyRentRevenueShareOnDineInStartYearCheck = false;
  let monthlyRentRevenueShareOnDeliveryInStartYearCheck = false;
  let landlordCheck = false;
  let leaseAddressCheck = false;
  let camCheck = false;
  const documentType = formDetails?.documentType?.value;
  const isMonthlyRentMgStartYear =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;
  if (isMonthlyRentMgStartYear) {
    monthlyRentMgStartYearCheck = !formDetails.monthlyRentMgStartYear?.trim();
  }
  const isMonthlyRentRevenueShareOnDineInStartYear =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;
  if (isMonthlyRentRevenueShareOnDineInStartYear) {
    monthlyRentRevenueShareOnDineInStartYearCheck =
      !formDetails.monthlyRentRevenueShareOnDineInStartYear?.trim();
  }
  const isMonthlyRentRevenueShareOnDeliveryInStartYear =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;
  if (isMonthlyRentRevenueShareOnDeliveryInStartYear) {
    monthlyRentRevenueShareOnDeliveryInStartYearCheck =
      !formDetails.monthlyRentRevenueShareOnDeliveryInStartYear?.trim();
  }
  const isLandlord =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;
  if (isLandlord) {
    landlordCheck = !formDetails.landlord?.trim();
  }
  const isLeaseAddress =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;
  if (isLeaseAddress) {
    leaseAddressCheck = !formDetails.leaseAddress?.trim();
  }
  const isCAM =
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement ||
    documentType ===
      agreementAndPostAgreementDocsLabelsAndValues.optionAgreement;

  if (isCAM) {
    camCheck = !formDetails.cam?.trim();
  }
  let leaseAgreementCheck = false;
  const isleaseAgreement =
    documentType ===
    agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement;
  if (isleaseAgreement) {
    leaseAgreementCheck =
      !formDetails?.landlordInvestment ||
      !formDetails?.rsecdeposit ||
      !formDetails?.rsecdepositinmonths ||
      !formDetails?.rentRevShareDeliveryExcludesAggregatorsComission;
  } else if (
    documentType ===
    agreementAndPostAgreementDocsLabelsAndValues.optionAgreement
  ) {
    leaseAgreementCheck =
      !formDetails?.rsecdeposit ||
      !formDetails?.rsecdepositinmonths ||
      !formDetails?.rentRevShareDeliveryExcludesAggregatorsComission;
  }
  const dateCheck = agrAndPostAgrCocoAcqDateCheck(formDetails);
  return (
    docsCheck ||
    monthlyRentMgStartYearCheck ||
    monthlyRentRevenueShareOnDineInStartYearCheck ||
    monthlyRentRevenueShareOnDeliveryInStartYearCheck ||
    landlordCheck ||
    leaseAddressCheck ||
    camCheck ||
    // !formDetails.documentDate ||
    !formDetails.documentType ||
    dateCheck ||
    leaseAgreementCheck
  );
};

export const mfaRiderCocoAcquired = (formDetails, docsCheck) => {
  let dateCheck = formDetails.notApplicable
    ? false
    : !formDetails.endDate || !formDetails.startDate;

  return docsCheck || dateCheck || !formDetails.documentType;
};
export const nocForGstCocoAcquired = (formDetails, docsCheck) => {
  const landlordNameMissing = !formDetails.nameOfLandload;
  const addressMissing = !formDetails.address;
  return landlordNameMissing || addressMissing || docsCheck;
};
export const electricityBillInNameOfLandloadCocoAcquired = (
  formDetails,
  docsCheck
) => {
  const landlordNameMissing = !formDetails.nameOfLandload;
  const addressMissing = !formDetails.address;
  return landlordNameMissing || addressMissing || docsCheck;
};
export const preAgreementDocsCocoAcquired = (formDetails, docsCheck) => {
  let startDateCheck = !formDetails.startDate;

  if (formDetails.notApplicable) {
    startDateCheck = false;
  }
  return docsCheck || !formDetails.documentType || startDateCheck;
};

export const waterTestingCocoValidation = formDetails => {
  return !formDetails.endDate || !formDetails.startDate;
};
