import { Button } from "@mui/material";
import { getPurchaseOrderAmount } from "../../purchase-order/helper";
import { getGRNOrderAmountWithTax } from "../grn-details/helper";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import { getOfflineData } from "../../../utils/offline-services";
import moment from "moment";

export const getHeaderConfig = (editVendor, status) => {
  const headers = [
    {
      label: "GRN NO",
      id: "grnNo",
    },
    {
      label: "PO Number",
      id: "poNumber",
      render: (data) => {
        const { purchaseOrderId = {} } = data;
        return (purchaseOrderId && purchaseOrderId.poNumber) || "";
      },
    },
    {
      label: "Site Code",
      id: "siteCode",
      render: (data) => {
        const { site = {} } = data || {};
        return (site && site.code) || "";
      },
    },
    {
      label: "Site Name",
      id: "siteName",
      render: (data) => {
        const { site = {} } = data;
        return (site && site.name) || "";
      },
    },
    {
      label: "Vendor Code",
      id: "vendorCode",
      render: (data) => {
        const { purchaseOrderId = {} } = data;
        return purchaseOrderId && purchaseOrderId.vendorCode;
      },
    },
    {
      label: "Vendor Name",
      id: "vendorName",
      render: (data) => {
        const { purchaseOrderId = {} } = data;
        return purchaseOrderId && purchaseOrderId.vendorName;
      },
    },
    {
      label: "Invoice Number",
      id: "invoiceNumber",
    },
    {
      label: "Invoice Date",
      id: "invoiceDate",
      render: (data) => {
        return data?.invoiceDate
          ? moment(data.invoiceDate).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      label: "Remarks",
      id: "remarks",
    },
    {
      label: status === "approval_in_progress" ? "Pending With" : "GRN Status",
      id: "status",
      render: (data) => {
        if (status === "approval_in_progress") {
          const { approvalId = {} } = data;
          return approvalId && approvalId.pendingWith;
        }
        return convertToCamelCaseFromUnderScore(data.status);
      },
    },
  ];
  if (status !== "approval_in_progress" && status !== "draft") {
    headers.push({
      label: "Pending With",
      id: "status",
      render: (data) => {
        const { approvalId = {} } = data;
        return approvalId && approvalId?.pendingWith;
      },
    });
  }

  headers.push(
    {
      label: "Total PO Value",
      id: "poValue",
      render: (data) => {
        const { purchaseOrderId = {} } = data;
        const { items = [] } = purchaseOrderId || {};
        return getPurchaseOrderAmount(items);
      },
    },
    {
      label: "Total GRN Value",
      id: "poValue",
      render: (data) => {
        const { purchaseOrderId = {}, receivedItems = [] } = data;
        const { items = [] } = purchaseOrderId || {};
        return getGRNOrderAmountWithTax(items, receivedItems);
      },
    }
  );

  if (status === "approved") {
    headers.push({
      label: "Payment Status",
      id: "paymentStatus",
      render: (data) => {
        return convertToCamelCaseFromUnderScore(data.paymentStatus || "");
      },
    });
  }

  if (true) {
    headers.push({
      label: "Created By",
      id: "creatorName",
    });
  }

  headers.push({
    label: "Actions",
    id: "actions",
    render: (data) => {
      const user = getOfflineData("user");
      const { status } = data;
      return (
        <>
          <Button variant="text" onClick={() => editVendor(data, "VIEW")}>
            View
          </Button>
          {["draft", "reconsider"].includes(status) &&
            parseInt(data?.createdBy) === parseInt(user?._id || user?.id) && (
              <Button variant="text" onClick={() => editVendor(data, "EDIT")}>
                Edit
              </Button>
            )}
        </>
      );
    },
  });
  return headers;
};
