
export const userStates = {
  isProcessing: 'Checking'
};


export const APPROVAL_TYPES = {
  BUDGET_APPROVAL: 'budget_approval',
  PO_APPROVAL: 'po_approval',
  LIME_ITEM_APPROVAL: 'lineitem_approval'
};
