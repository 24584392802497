import React from "react";
import CustomModal from "../../../../common-utilities/core/modal";
import { Button, Box, Typography } from "@mui/material";

const ConfirmApprove = ({
  bulkApprove,
  approvedPODetails,
  setIsConfirmModalOpen
}) => {
  return (
    <div>
      <CustomModal
        title="Confirmation"
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <Typography>
          Number of PO's : {approvedPODetails.totalResults}
        </Typography>
        <Typography>
          Total approval amount : ₹{approvedPODetails.totalSum}
        </Typography>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={bulkApprove}>
            Submit
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
};

export default ConfirmApprove;
