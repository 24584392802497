import { lakhConversion } from "../../../../../common-utilities/utils/misc2";
import {
  formatedPercentageRender,
  getConfigFields,
  getSlicedNum,
  formattedDateRender,
} from "../../utils";

export const storeDetailsConfig = (filters, value) => {
  const config = [];

  switch (value) {
    case 0:
      config.push({
        label: "Format",
        id: "format",
      });
      break;
    case 1:
      config.push(
        {
          label: "Carpet Area - Sq ft",
          id: "carpetAreaSqft",
        },
        {
          label: "Built Area",
          id: "builtArea",
        },
        {
          label: "Lat Long",
          id: "latLong",
        },
        {
          label: "Lease Type",
          id: "leaseType",
        },
        {
          label: "Lease Start Date",
          id: "leaseStartDate",
          render: (data) => formattedDateRender(data, "leaseStartDate"),
          isDateLabel: true,
        },
        {
          label: "Lease End Date",
          id: "leaseEndDate",
          render: (data) => formattedDateRender(data, "leaseEndDate"),
          isDateLabel: true,
        }
      );
      break;
    default:
      break;
  }

  return getConfigFields(config, filters, "storeDetails");
};

export const investmentConfig = (filters) => {
  const config = [
    {
      label: "Gross Offer extended -A",
      id: "grossOfferExtendedA",
      render: (data) => getSlicedNum(data, "grossOfferExtendedA"),
      removeDecimal: true,
    },
    {
      label: "Remodel adj to Franchisee",
      id: "remodelAdjToFranchisee",
      render: (data) => getSlicedNum(data, "remodelAdjToFranchisee"),
      removeDecimal: true,
    },
    {
      label: "Net offer extended",
      id: "netOfferExtended",
      render: (data) => getSlicedNum(data, "netOfferExtended"),
      removeDecimal: true,
    },
    {
      label: "EIPL Remodel Adj Cost",
      id: "eiplRemodelAdjCost",
      render: (data) => getSlicedNum(data, "eiplRemodelAdjCost"),
      removeDecimal: true,
    },
    {
      label: "LCM fees incl Legal",
      id: "lcmFeesInclLegal",
      render: (data) => getSlicedNum(data, "lcmFeesInclLegal"),
      removeDecimal: true,
    },
    {
      label: "Title DD",
      id: "titleDD",
      render: (data) => getSlicedNum(data, "titleDD"),
      removeDecimal: true,
    },
    {
      label: "Valuation Fees",
      id: "valuationFees",
      render: (data) => getSlicedNum(data, "valuationFees"),
      removeDecimal: true,
    },
    {
      label: "Stamp Duty & Rgstn Cost",
      id: "stampDutyRgstnCost",
      render: (data) => getSlicedNum(data, "stampDutyRgstnCost"),
      removeDecimal: true,
    },
    {
      label: "Transfer fee",
      id: "transferFee",
      render: (data) => getSlicedNum(data, "transferFee"),
      removeDecimal: true,
    },
    {
      label: "Change Licensing costs",
      id: "changeLicensingCosts",
      render: (data) => getSlicedNum(data, "changeLicensingCosts"),
      removeDecimal: true,
    },
    {
      label: "TOTAL OTHER COST",
      id: "totalOtherCost",
      render: (data) => getSlicedNum(data, "totalOtherCost"),
      removeDecimal: true,
    },
    {
      label: "Total Acquisition Cost",
      id: "totalCapex",
      removeDecimal: true,
      value: (item) => lakhConversion(item.totalCapex),
      valueConversionLogic:(val) => lakhConversion(val, true, true)
    },
    {
      label: "Labour - SA",
      id: "labourSa",
      render: (data) => getSlicedNum(data, "labourSa"),
      removeDecimal: true,
    },
    {
      label: "Labour - SM",
      id: "labourSm",
      render: (data) => getSlicedNum(data, "labourSm"),
      removeDecimal: true,
    },
    {
      label: "Labour - ARM",
      id: "labourArm",
      render: (data) => getSlicedNum(data, "labourArm"),
      removeDecimal: true,
    },
    {
      label: "Labour - RM",
      id: "labourRm",
      render: (data) => getSlicedNum(data, "labourRm"),
      removeDecimal: true,
    },
    {
      label: "Labour Cost - SA",
      id: "labourCostSa",
      render: (data) => getSlicedNum(data, "labourCostSa"),
      removeDecimal: true,
    },
    {
      label: "Labour Cost - SM",
      id: "labourCostSm",
      render: (data) => getSlicedNum(data, "labourCostSm"),
      removeDecimal: true,
    },
    {
      label: "Labour Cost - ARM",
      id: "labourCostArm",
      render: (data) => getSlicedNum(data, "labourCostArm"),
      removeDecimal: true,
    },
    {
      label: "Labour Cost - RM",
      id: "labourCostRm",
      render: (data) => getSlicedNum(data, "labourCostRm"),
      removeDecimal: true,
    },
  ];
  return getConfigFields(config, filters, "investment");
};

export const occupancy1Config = (filters) => {
  const config = [
    {
      label: "MG Yr1 permonth in Rs.",
      id: "mgYr1Permonth",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "mgYr1Permonth"),
    },
    {
      label: "MG Yr 2",
      id: "mgYr2",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "mgYr2"),
    },
    {
      label: "MG Yr 3",
      id: "mgYr3",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "mgYr3"),
    },
    {
      label: "CAM Yr1 per month in Rs",
      id: "camYr1PmInRs",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "camYr1PmInRs"),
    },
    {
      label: "RS Dine In Yr1",
      id: "rsDineInYr1Percentage",
      render: (data) => formatedPercentageRender(data, "rsDineInYr1Percentage"),
      value: (data) =>
        formatedPercentageRender(data, "rsDineInYr1Percentage", true),
      percentageConversion: true,
    },
    {
      label: "RS Del In Yr1",
      id: "rsDelInYr1Percentage",
      render: (data) => formatedPercentageRender(data, "rsDelInYr1Percentage"),
      value: (data) =>
        formatedPercentageRender(data, "rsDelInYr1Percentage", true),
      percentageConversion: true,
    },
    {
      label: "Other Property charges Yr1 per month in Rs",
      id: "otherPropertyChargesYr1Pm",
      removeDecimal: true,
      render: (data) => getSlicedNum(data, "otherPropertyChargesYr1Pm"),
    },
  ];
  return getConfigFields(config, filters, "occupanyYear1");
};
