import { Route, Routes } from "react-router-dom";
import SmallwareOrderingRoute from './private-route'
import Home from "../components/home";
import CreatePO from "../components/purchase-order/create-po";
import PODetails from "../components/purchase-order/po-details";
import EditPO from "../components/purchase-order/edit-po";

const SmallwareOrderingRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<SmallwareOrderingRoute />}>
        <Route exact path="/" element={<Home />} />        
        <Route
          exact
          path="purchase-order/create/:budgetId"
          element={<CreatePO />}
        />        
        <Route exact path="purchase-order/:id" element={<PODetails />} />
        <Route exact path="purchase-order/edit/:id" element={<EditPO />} />        
      </Route>
    </Routes>
  );
};

export default SmallwareOrderingRoutes;
