import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import MasterData from "../master-data";
import PurchaseOrder from "../purchase-order";
import { getOfflineData } from "../../utils/offline-services";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const userDetails = getOfflineData("user") || {};

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setSearchParams({
        index: newValue,
        subIndex: 2
      });
    } else if (newValue === 0) {
      setSearchParams({
        index: newValue,
        subIndex: 0
      });
    } else if (newValue === 2) {
      setSearchParams({
        index: newValue,
        subIndex: 5
      });
    } else if (newValue === 3) {
      setSearchParams({
        index: newValue,
        subIndex: 5
      });
    } else if (newValue === 5) {
      setSearchParams({
        index: newValue,
        subIndex: 4
      });
    } else {
      setSearchParams({
        index: newValue,
        subIndex: 0
      });
    }
    setValue(newValue);
  };

  const role = userDetails?.role?.toUpperCase();

  return (
    <div className="home-container">
      <div className="tabs-section">
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            display: "flex"
          }}
        >
          <div style={{ minWidth: "fit-content" }}>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              sx={{
                width: "100%",
                "& button": {
                  color: "black",
                  borderBottom: "1.5px solid #008a52"
                },
                "& .Mui-selected": {
                  borderBottom: "none"
                }
              }}
              TabIndicatorProps={{
                sx: {
                  display: "none"
                }
              }}
            >
              <Tab label="PO" className={value == 0 && "active-tab"} />
              {role?.includes("ADMIN") && (
                <Tab label="Masters" className={value == 1 && "active-tab"} />
              )}
            </Tabs>
          </div>
          <div
            style={{ width: "100%", borderBottom: "1.5px solid #008a52" }}
          ></div>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <PurchaseOrder role={role} />
      </TabPanel>

      {role?.includes("ADMIN") && (
        <TabPanel value={value} index={1}>
          <MasterData role={role} />
        </TabPanel>
      )}
    </div>
  );
};

export default Home;
