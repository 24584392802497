import { getLabelsAndValuesForSelect } from "../../common-utilities/utils";

export const accquiredPreAgreementDocsLabelsAndValues = {
  // commercialTermSheet: "Commercial Term Sheet",
  projectFeasibility: "Project Feasibility",
  LOI: "LOI",
  titleDeligenceReport: "Title Deligence Report",
  licenseFeasibility: "License Feasibility",
  // possessionLetter: "Possession Letter",
};
export const agreementAndPostAgreementDocsLabelsAndValuesCocoBuilt = {
  leaseAgreement: "Lease Agreement",
  registration: "Registration",
  submissionOfRegHardCopy: "Submisssion of Reg Hard copy",
  optionAgreement: "Option Agreement",
};
export const agreementAndPostAgreementDocsLabelsAndValues = {
  bta: "BTA",
  franchiseTermination: "Franchise Termination",
  leaseAgreement: "Lease Agreement",
  registration: "Registration",
  submissionOfRegHardCopy: "Submisssion of Reg Hard copy",
  deliveryMemo: "Delivery Memo",
  leaseTermination: "Lease Termination",
  optionAgreement: "Option Agreement",
};

export const agreementAndPostAgreementDocsNa = [
  agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement,
  agreementAndPostAgreementDocsLabelsAndValues.registration,
  agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy,
];

export const validCocoAcquiredLicenses = {
  fssai: "FSSAI",
  fire: "Fire NOC",
  changeOfUser: "Change of User",
  trade: "Trade",
  shopEstablishment: "Shop Establishment",
  dgLicence: "DG License",
  weightAndMeasurement: "Weight and Measurement",
  cto: "CTO",
  cte: "CTE",
  signage: "Signage",
  ppliceNOC: "Police NOC",
  others: "Others",
};

export const mfaRiderSubCategories = {
  rider: "Riders",
};
export const nocForGstSubCategories = {
  nocForGst: "NOC For GST",
};
export const ElectricityBillInNameOfLandloadSubCategories = {
  electricityBillInNameOfLandload: "Electricity Bill In Name Of Landload",
};
export const mfaRidersSubCategoriesList = Object.values(mfaRiderSubCategories);

export const getrsecDepositInMonthsSelectList = () => {
  let numbericSelectValues = new Array(11)
    .fill(0)
    .map((_, index) => `${index + 2}`);
  const allrsecDepositValuesList = [...numbericSelectValues, "Lumpsum"];
  const allrsecDepositValuesSelectList = getLabelsAndValuesForSelect(
    allrsecDepositValuesList,
    allrsecDepositValuesList
  );
  return allrsecDepositValuesSelectList;
};

export const getRentRevShareDeliveryExcludesAggregatorsCommissionSelectList =
  () => {
    const allValues = ["Yes", "No"];
    return getLabelsAndValuesForSelect(allValues, allValues);
  };

export const agrAndPostAgrDocsDateConfig = (
  documentType,
  formDetails,
  errors
) => {
  const config = [];
  const documentDate = {
    label: "Document Date*",
    name: "startDate",
    type: "date",
    value: formDetails.startDate,
    error: errors.startDate,
    helperText: errors.startDate ? "Required" : "",
  };

  const startDate = {
    label: "Start Date*",
    name: "startDate",
    type: "date",
    value: formDetails.startDate,
    error: errors.startDate,
    helperText: errors.startDate ? "Required" : "",
  };

  const endDate = {
    label: "Expiry Date*",
    name: "endDate",
    type: "date",
    value: formDetails.endDate,
    error: errors.endDate,
    helperText: errors.endDate ? "Required" : "",
  };

  switch (documentType) {
    case agreementAndPostAgreementDocsLabelsAndValues.bta:
    case agreementAndPostAgreementDocsLabelsAndValues.franchiseTermination:
    case agreementAndPostAgreementDocsLabelsAndValues.deliveryMemo:
    case agreementAndPostAgreementDocsLabelsAndValues.leaseTermination:
      config.push(documentDate);
      break;

    case agreementAndPostAgreementDocsLabelsAndValues.leaseAgreement:
    case agreementAndPostAgreementDocsLabelsAndValues.registration:
    case agreementAndPostAgreementDocsLabelsAndValues.optionAgreement:
      config.push(startDate);
      config.push(endDate);
      break;

    case agreementAndPostAgreementDocsLabelsAndValues.submissionOfRegHardCopy:
      config.push(startDate);
      break;

    default:
      break;
  }
  return config;
};
