/** @format */
const commonPrefix = "/v1/fininv-service/";
const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  LOGIN: `${commonPrefix}auth/login`,
  // REFRESH_TOKEN: `${commonPrefix}auth/refresh-tokens`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGOUT: `${commonPrefix}auth/logout`,
  INVOICE: `${commonPrefix}invoices`,
  VENDOR_INVOICE: `${commonPrefix}vendor-invoice`,
  APPROVE_INVOICE: `${commonPrefix}invoices/approval/rm/`,
  AM_APPROVE_INVOICE: `${commonPrefix}invoices/approval/am/`,
  FINANCE_INVOICE: `${commonPrefix}invoices/approval/finance/`,
  VENDOR_FINANCE_INVOICE: `${commonPrefix}vendor-invoice/approval/finance/`,
  VENDOR: `${commonPrefix}vendor`,
  INVOICE_ATTACHMENT: `${commonPrefix}invoices/upload/attachment`,
  LIST_ALL_USERS: `${intranetService}other-app/all-user-names`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  STORE_DETAILS: `${intranetService}stores/by-store-number`,
  FRANCHISE_STORE: `${commonPrefix}store/franchise-store`,
  GET_ALL_STORES: `${intranetService}stores`,
  GET_PAYMENT_PENDING_INVOICE: `${commonPrefix}vendor-invoice/payment-pending-invoice`,
  UPLOAD_PAYMENT_INVOICES: `${commonPrefix}vendor-invoice/bulk-update`,
  VENDOR_LIST: `${commonPrefix}vendor/all-vendor-list`,
};
export const redirectToRoute = "/invoice-tracking";
