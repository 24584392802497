import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { INTRANETHOSTNAME } from "../../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";

export const formSubmitHandler = (
  selectedApps,
  selectedRoles,
  formData,
  editUser,
  isEmployee,
  resetPasswordOnFirstLogin,
  closeModal,
  onSuccess
) => {
  const limits = {};
  Object.values(selectedApps).forEach((app) => {
    const appId = app?.value;
    const role = selectedRoles[appId]?.value;
    limits[appId] = role;
  });

  const payload = {
    email: formData.email,
    role: formData.role,
    password: formData.password,
    limits,
    name: formData.name,
    isEmployee: isEmployee,
    financeRoles: formData?.financeRoles || [],
    activityStatus: formData?.activityStatus,
  };
  // const { departmentRole, department, vendor } = formData;
  const { department, vendor } = formData;
  // if (departmentRole && department) {
  //   payload.departmentRole = departmentRole;
  //   payload.department = department;
  // }
  payload.department = department || [];

  if (editUser && resetPasswordOnFirstLogin && formData?.password) {
    payload["changePasswordOnFirstLogin"] = resetPasswordOnFirstLogin;
  }
  if (editUser && !formData?.password) {
    delete payload.password;
  }
  if (formData.role === "user" && formData?.additionalRoles?.length > 0) {
    payload["additionalRoles"] = formData?.additionalRoles;
  }
  if (formData.role === "vendor") {
    payload["vendor"] = vendor;
    payload.department = [];
  } else {
    payload["vendor"] = [];
  }

  const { _id } = formData;
  const url = editUser
    ? `${INTRANETHOSTNAME}${REST_URLS.UPDATE_INTRANET_USER}${_id}`
    : `${INTRANETHOSTNAME}${REST_URLS.CREATE_INTRANET_USER}`;
  const message = editUser
    ? "User updated successfully"
    : "User created successfully";
  const method = editUser ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
  invokeApi(method, url, payload)
    .then((res) => {      
      if (res?.message || res?.code === 500) {
        toast.error(res?.message || "Failed to update", { autoClose: 2000 });
        return;
      }
      toast.success(message, { autoClose: 2000 });
      closeModal(false);
      onSuccess && onSuccess();
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};
