// these are the main enpoint for project
export const endPointsForProject = {
  trainingManual: "/training-manual/*",
  docuStorage: "/docu-storage/*",
  storeMaster: "/store-master/*",
  technet: "/tech-net/*",
  vendorOnboard: "/vendor-onboard/*",
  subs: "/subs/*",
  qnet: "/qnet/*",
  policies: "/policies/*",
  memo: "/memo/*",
  subwayFinance: "/subway-finance/*",
  pettyExpense: "/petty-expense/*",
  franchisePolicy: "franchise-policy/*",
  vendorInvoiceSubmission: "vendor-invoice-submission/*",
  invoiceTracking: "invoice-tracking/*",
  plReport: "pl-report/*",
  electricConsumption: "electric-consumption/*",
  SmallwareOrdering: "smallware-ordering/*",
};

// From endPointsForProject we extract end point
export const getProjectEndPoint = (endPoint) => {
  // If we do not supply end point then we get empty string
  if (!endPoint || typeof endPoint != "string") {
    return "";
  }
  return endPoint?.split("/*")?.[0];
};
