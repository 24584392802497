import { VENDOR_ONBOARD_REST_URLS } from "../../common-utilities/utils/end-points/vendor-onboard";
import { HTTP_METHODS, invokeApi } from "./http-service";
import { clearOfflineData, setOfflineData } from "./offline-services";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { getOfflineData } from "./offline-services";
export const redirectToIntranetPortal = () => {
  clearOfflineData("user");
  clearOfflineData("tokens");
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const getUserDetailsFromIntranet = (
  Authorization,
  customLogic,
  tokens,
  params
) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${VENDOR_ONBOARD_REST_URLS.INTRANET_LOGIN}`,
    null,
    params,
    Authorization
  )
    .then(res => {
      if (res?.message) {
        redirectToIntranetPortal();
        return;
      }
      if (tokens) {
        setOfflineData("tokens", tokens);
      }
      const role = res?.role?.toUpperCase();
      setOfflineData("user", { ...res, role });
      if (typeof customLogic === "function") {
        customLogic(res);
      }
    })
    .catch(() => {
      redirectToIntranetPortal();
    });
};

export const getFileNameType = url => {
  if (url) {
    const urlSplits = url.split("/");
    if (urlSplits.length > 0) {
      const lastSplits = urlSplits[urlSplits.length - 1].split(".");
      return lastSplits[lastSplits.length - 1];
    }
  }

  return "";
};

export const getFileName = url => {
  if (url) {
    const urlSplits = url.split("/");
    if (urlSplits.length > 0) {
      return urlSplits[urlSplits.length - 1];
    }
  }

  return "";
};

export const isCreateAllowed = role => {
  return ["USER", "HOD"].includes(role);
};

export const isUploadAllowed = role => {
  return ["ADMIN", "USER", "HOD"].includes(role);
};

export const isDeleteAllowed = role => {
  return ["ADMIN", "USER", "HOD"].includes(role);
};

export const isUpdateAllowed = role => {
  return ["ADMIN", "USER", "HOD"].includes(role);
};

export const isViewAllowed = role => {
  return ["ADMIN", "USER", "HOD"].includes(role);
};
export const isActionAllowed = role => {
  return ["ADMIN", "USER", "HOD"].includes(role);
};

export const isApprovedAllowed = () => {
  const userDetails = getOfflineData("user") || {};
  const role = userDetails?.role?.toUpperCase() || "";
  return ["ADMIN","HOD"].includes(role);
};
