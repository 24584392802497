import {
  getLabelsAndValuesForSelect,
  pick,
} from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import {
  DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE,
  validStoreTypes,
} from "../../utils/document-constants";

import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { cocoAcquiredInputsForForm } from "./config/coco-acquired.config";
import { cocoBuiltInputsForm } from "./config/coco-built.config";
import { fofoInputsForm } from "./config/fofo.config";
import { getFormDetailsFromGetDocumentApi } from "./utils";

export const getFormConfig = (
  formDetails,
  errors,
  states,
  cities,
  storeType,
  isEditingDoc,
  docs
) => {
  const subFormOnTheBasisOfStoreType = {
    [validStoreTypes.cocoAcquired]: cocoAcquiredInputsForForm(
      formDetails,
      errors,
      storeType,
      isEditingDoc,
      docs
    ),
    [validStoreTypes.cocoBuilt]: cocoBuiltInputsForm(
      formDetails,
      errors,
      storeType,
      isEditingDoc,
      docs
    ),
    [validStoreTypes.fofo]: fofoInputsForm(formDetails, errors, storeType, isEditingDoc,
      docs),
  };

  const mainDocumentTypes = DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE[storeType];
  const allLabels = getLabelsAndValuesForSelect(
    mainDocumentTypes,
    mainDocumentTypes
  );

  let data = [
    {
      label: "Document Type*",
      name: "type",
      type: "select",
      value: formDetails.type,
      error: errors.type,
      options: allLabels,
      helperText: errors.type ? "Required" : "",
    },
  ];

  if (formDetails.type) {
    const typeDetails =
      subFormOnTheBasisOfStoreType?.[storeType][formDetails.type?.value] || [];
    return [...data, ...typeDetails];
  }

  return data;
};

export const uploadDocumenTypeFormatConfig = {
  message: "You can upload only pdf and images",
  fileFormats: [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/svg+xml",
    "application/pdf",
  ],
  acceptType: "image/png, image/jpg, image/jpeg,image/svg+xml, application/pdf",
};

const resetFormDocs = (
  name,
  value,
  formDetails,
  setFormDetails,
  setDocs,
  setIsEditingDoc,
  setDisableEditing
) => {
  setDocs([]);
  setIsEditingDoc(true);
  setTimeout(() => {
    const { type } = formDetails;
    setFormDetails({ type, [name]: value });
    setIsEditingDoc(false);
  }, 250);
  setDisableEditing(true);
};

// restaurantId is replaced with store in intranet migration, hence we we make request we sent send store instead of restaurant id
export const getDataForAlreadyUploadedDocument = (
  event,
  storeType,
  restaurantId,
  formDetails,
  setFormDetails,
  setIsEditingDoc,
  setDocs,
  setDisableEditing
) => {
  const name = event?.target?.name;
  const value = event?.target?.value?.value;
  const resetValue = event?.target?.value;
  const isStoreFofo = storeType === validStoreTypes.fofo;
  const hasUserSelectedDocumentType = name === "type";
  const hasUserSelectedSubDocumentType = name === "documentType";
  const hasUserSelectedLicenseType = name === "licenseType";
  let hasToFetchData = false;
  let payload = { store: restaurantId, storeType };
  if (isStoreFofo) {
    if (hasUserSelectedDocumentType) {
      payload.type = value;
      hasToFetchData = true;
    }
  } else {
    const hasUserSelectedSubDocument =
      hasUserSelectedSubDocumentType || hasUserSelectedLicenseType;
    if (hasUserSelectedSubDocument) {
      const typeOfDocument = formDetails?.type?.value;
      payload.type = typeOfDocument;
      if (hasUserSelectedLicenseType) {
        payload.licenseType = value;
      } else {
        payload.documentType = value;
      }
      hasToFetchData = true;
    }
  }
  if (hasToFetchData) {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_ONE_UPLOAD_DOCUMENT}`,
      null,
      payload
    )
      .then((res) => {
        if (res?.message) {
          resetFormDocs(
            name,
            resetValue,
            formDetails,
            setFormDetails,
            setDocs,
            setIsEditingDoc,
            setDisableEditing
          );
          return;
        }
        setIsEditingDoc(false);
        const formData = getFormDetailsFromGetDocumentApi(
          res?.details || {},
          setDocs,
          setIsEditingDoc,
          res?.type,
          res?.storeType
        );
        setDisableEditing(!!!res?.editAccess);
        setFormDetails((prevDetails) => {
          const allDetails = pick(prevDetails, [
            "type",
            "documentType",
            "licenseType",
          ]);
          return { ...allDetails, ...formData };
        });
      })
      .catch((err) => {
        resetFormDocs(
          name,
          resetValue,
          formDetails,
          setFormDetails,
          setDocs,
          setIsEditingDoc,
          setDisableEditing
        );
        console.log(err);
      });
  }
};
