import {
  preAgreementDocsLabelsAndValues,
  agreementAndPostAgreementDocsLabelsAndValues,
  mfaRiderSubCategories,
  nocForGstSubCategories,
  ElectricityBillInNameOfLandloadSubCategories,
} from "../../../utils/coco-built.config";
import { validCocoBuiltLicenses } from "../../../utils/coco-built.config";
import {
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import { showCocoBuiltLicenseTypeBoxInForm } from "../utils/coco-built-utils";
import { getLabelsAndValuesForSelect } from "../../../../common-utilities/utils";
import {
  agreementAndPostAgreementDocsConfig,
  mfaRiderConfig,
  preAgreementDocsConfig,
  electricityBillInNameOfLandloadConfig,
  nocForGstConfig,
  waterTestingConfig,
} from "../sub-configs/coco-built";

export const cocoBuiltInputsForm = (
  formDetails,
  errors,
  storeType,
  isEditingDoc,
  docs
) => {
  if (storeType !== validStoreTypes.cocoBuilt) {
    return {};
  }
  const cocoBuiltLicenses = Object.values(validCocoBuiltLicenses);
  const cocoBuiltLicensesLabels = getLabelsAndValuesForSelect(
    cocoBuiltLicenses,
    cocoBuiltLicenses
  );
  const preAgreementSelectList = Object.values(preAgreementDocsLabelsAndValues);
  const preAgreementSelectLabelsAndValues = getLabelsAndValuesForSelect(
    preAgreementSelectList,
    preAgreementSelectList
  );

  const agreementAndPostAgreementSelectList = Object.values(
    agreementAndPostAgreementDocsLabelsAndValues
  );
  const agreementAndPostAgreementLabelsAndValues = getLabelsAndValuesForSelect(
    agreementAndPostAgreementSelectList,
    agreementAndPostAgreementSelectList
  );

  const mfaRiderSubCategoriesList = Object.values(mfaRiderSubCategories);
  const mfaRidersSelectLabelsAndValues = getLabelsAndValuesForSelect(
    mfaRiderSubCategoriesList,
    mfaRiderSubCategoriesList
  );
  const nocForGstSubCategoriesList = Object.values(nocForGstSubCategories);
  const electricityBillInNameOfLandloadSubCategoriesList = Object.values(
    ElectricityBillInNameOfLandloadSubCategories
  );
  const nocForGstSelectLabelsAndValues = getLabelsAndValuesForSelect(
    nocForGstSubCategoriesList,
    nocForGstSubCategoriesList
  );
  const electricityBillInNameOfLandloadSelectLabelsAndValues =
    getLabelsAndValuesForSelect(
      electricityBillInNameOfLandloadSubCategoriesList,
      electricityBillInNameOfLandloadSubCategoriesList
    );
  const isAppliedOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const config = {
    [validDocumentTypes.preAgreementDocs]: [
      {
        label: "Sub Document*",
        name: "documentType",
        type: "select",
        placeholder: "Select document type",
        value: formDetails.documentType,
        error: errors.documentType,
        options: preAgreementSelectLabelsAndValues,
        helperText: errors.documentType ? "Required" : "",
      },
    ],
    [validDocumentTypes.agreementAndPostAgreementDocs]: [
      {
        label: "Sub Document*",
        name: "documentType",
        placeholder: "Select document type",
        type: "select",
        value: formDetails.documentType,
        error: errors.documentType,
        options: agreementAndPostAgreementLabelsAndValues,
        helperText: errors.documentType ? "Required" : "",
      },
    ],
    [validDocumentTypes.license]: [
      {
        label: "License Type*",
        name: "licenseType",
        type: "select",
        placeholder: "Select license type",
        value: formDetails.licenseType,
        error: errors.licenseType,
        options: cocoBuiltLicensesLabels,
        helperText: errors.licenseType ? "Required" : "",
      },
      {
        label: "Is Applied",
        name: "isApplied",
        type: "select",
        placeholder: "Is Applied",
        value: formDetails.isApplied,
        error: errors.isApplied,
        options: isAppliedOptions,
        disabled: isEditingDoc && !!docs?.length,
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.licenseUpload,
        error: errors.licenseUpload,
        helperText: errors.licenseUpload ? "Required" : "",
        documentSavingKey: "document",
        uploadType: "licenceType",
        notUploadCheckBox: showCocoBuiltLicenseTypeBoxInForm(
          formDetails,
          storeType
        ),
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.mfaRider]: [
      {
        label: "Sub Document*",
        name: "documentType",
        type: "select",
        placeholder: "Select document typr",
        value: formDetails.documentType,
        error: errors.documentType,
        options: mfaRidersSelectLabelsAndValues,
        helperText: errors.documentType ? "Required" : "",
      },
    ],
    [validDocumentTypes.nocForGst]: [
      {
        label: "Sub Document*",
        name: "documentType",
        type: "select",
        placeholder: "Select document type",
        value: formDetails.documentType,
        error: errors.documentType,
        options: nocForGstSelectLabelsAndValues,
        helperText: errors.documentType ? "Required" : "",
      },
    ],
    [validDocumentTypes.electricityBillInNameOfLandload]: [
      {
        label: "Sub Document*",
        name: "documentType",
        type: "select",
        placeholder: "Select document type",
        value: formDetails.documentType,
        error: errors.documentType,
        options: electricityBillInNameOfLandloadSelectLabelsAndValues,
        helperText: errors.documentType ? "Required" : "",
      },
    ],
    [validDocumentTypes.waterTesting]: [ {
      label: "Sub Document*",
      name: "documentType",
      type: "select",
      placeholder: "Select document typr",
      value: formDetails.documentType,
      error: errors.documentType,
      options: mfaRidersSelectLabelsAndValues,
      helperText: errors.documentType ? "Required" : "",
    },]
  };

  switch (formDetails?.type?.value) {
    case validDocumentTypes.preAgreementDocs:
      preAgreementDocsConfig(formDetails, config, errors);
      break;
    case validDocumentTypes.agreementAndPostAgreementDocs:
      agreementAndPostAgreementDocsConfig(formDetails, config, errors);
      break;
    case validDocumentTypes.license:
      if (!formDetails?.notApplicable) {
        config[validDocumentTypes.license].splice(
          1,
          0,
          {
            label: "Start Date*",
            name: "startDate",
            type: "date",
            value: formDetails.startDate,
            error: errors.startDate,
            helperText: errors.startDate ? "Required" : "",
          },
          {
            label: "Expiry Date*",
            name: "endDate",
            type: "date",
            value: formDetails.endDate,
            error: errors.endDate,
            helperText: errors.endDate ? "Required" : "",
          }
        );
      } else {
        delete formDetails.endDate;
        delete formDetails.startDate;
      }
      if (formDetails?.licenseType?.value === validCocoBuiltLicenses.others) {
        config[validDocumentTypes.license] = [
          config[validDocumentTypes.license][0],
          {
            placeholder: "License Name",
            label: "License Name*",
            name: "licenseName",
            type: "text",
            value: formDetails.licenseName,
            error: errors.licenseName,
            helperText: errors.licenseName ? "Required" : "",
          },
          ...config[validDocumentTypes.license].slice(
            1,
            config[validDocumentTypes.license].length
          ),
        ];
      } else {
        delete formDetails.licenseName;
      }
      break;
    case validDocumentTypes.mfaRider:
      mfaRiderConfig(formDetails, config, errors);
    case validDocumentTypes.waterTesting:
      waterTestingConfig(formDetails, config, errors);
      break;
    case validDocumentTypes.nocForGst:
      nocForGstConfig(formDetails, config, errors);
      break;
    case validDocumentTypes.electricityBillInNameOfLandload:
      electricityBillInNameOfLandloadConfig(formDetails, config, errors);
      break;
    default:
      break;
  }
  return config;
};
