import {  
  exportToExcel
} from "../../../../common-utilities/utils";
export const downloadPO = poData => {
  const data =
    poData?.items?.map?.(item => {
      return {
        "Item Code": item?.itemCode || null,
        Description: item?.itemDescription || null,
        UOM: item?.uom || null,
        Type: item?.type || null,
        CaseSize: item?.caseSize || null,
        Quantity: item?.quantity != undefined ? item?.quantity : null,
        // "Inward Qty":
        //   item?.inwardedQuantity != undefined ? item?.inwardedQuantity : null,
        Price: item?.rate != undefined ? item?.rate : null,
        Amount: item?.amount != undefined ? item?.amount : null,
        "Tax rate": item?.taxRate != undefined ? item?.taxRate : null,
        "Tax Amount": item?.taxAmount != undefined ? item?.taxAmount : null,
        "Total Amount":
          item?.totalAmount != undefined ? item?.totalAmount : null
      };
    }) || [];

  exportToExcel(data, "po", null, true);
};

export const downloadReports = (data, status) => {
  if (!data?.length) {
    return;
  }

  exportToExcel(data, "po_report");
};
