import ActionIcons from "../actionIcons";
import EscalationMatrix from "../EscalationMatrix";
import { userTypes } from "../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";

export const getHeaderConfig = (
  loadData,
  departmentList,
  adminAndTechNetUsersList,
  escalationToUsersList
) => {
  const headerConfig = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Description",
      id: "desc",
    },
    {
      label: "Department",
      id: "dept",
    },
    {
      label: "EscalationMatrix",
      id: "escalationMatrix",
      render: (data) => {
        return <EscalationMatrix data={data.escalationMatrix} />;
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <div className="ticket-action-icons">
            <ActionIcons
              loadData={loadData}
              departmentList={departmentList}
              id={data.id}
              name={data.name}
              dept={data.dept}
              deptId={data.deptId}
              escList={data.escalationMatrix}
              fullDesc={data.fullDesc}
              modalType="CATEGORY"
              parent={data.parent}
              adminAndTechNetUsersList={adminAndTechNetUsersList}
              userPrimaryContact={data.primaryContact}
              userSecondaryContact={data.secondaryContact}
              userTertiaryContact={data.tertiaryContact}
              escalationToUsersList={escalationToUsersList}
              isActive={data.isActive}
              additionalDetails={{
                attachments: data?.attachments || [],
                storeType: data?.storeType,
              }}
            />
          </div>
        );
      },
    },
  ];

  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData(tokenMainKeys.technet, "user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }

  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Description",
    id: "desc",
  },
];
