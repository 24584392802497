import { monthNumberMap } from "../../../common-utilities/utils/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export const getElectricityTableHeader = () => {
  const headers = [
    {
      label: "Store",
      id: "store",
      render: (data) => {
        return <div>{data?.storeId?.name}</div>;
      },
    },

    {
      label: "Month",
      id: "monthName",
      render: (data) => {
        return monthNumberMap[data.month];
      },
    },

    { label: "Year", id: "year" },
    {
      label: "Daily Readings",
      id: "dailyReading",
      render: (data, onClick) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "VIEW_READINGS")}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      label: "Total Consumption",
      id: "totalMonthConsumption",
      render: (data) => {
        const calculateTotalConsumption = (dailyReadings) => {
          return Object.values(dailyReadings).reduce((total, reading) => {
            return total + (reading.difference + reading.previousNightUnits);
          }, 0);
        };
        return calculateTotalConsumption(data.dailyReadings);
      },
    },
    {
      label: "Unit Consumption",
      id: "totalMonthConsumption",
      render: (data) => {
        const calculateTotalConsumption = (dailyReadings) => {
          return Object.values(dailyReadings).reduce((total, reading) => {
            return total + reading.unitsConsumed;
          }, 0);
        };
        return calculateTotalConsumption(data.dailyReadings);
      },
    },
    { label: "Total Consumption Amount(INR)", id: "totalMonthConsumption" },
  ];

  return headers;
};
