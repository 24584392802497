import {
  validDocumentTypes,
  validStoreTypes,
  reportLabelKeys
} from "../../../utils/document-constants";

export const setClassForSiTableOnTheBasisOfReports = (
  reportType,
  userFilters,
  setcustomSiRowClass
) => {
  const curReport = reportType?.value;
  const storeType = userFilters?.storeType?.value;
  const document = userFilters.documentType?.value;
  switch (curReport) {
    case reportLabelKeys.general:
      setcustomSiRowClass("");
      return;
    case reportLabelKeys.upload:
      switch (storeType) {
        case validStoreTypes.cocoBuilt:
          switch (document) {
            case validDocumentTypes.preAgreementDocs:
              setcustomSiRowClass("coco-built-lease-agreement-upload");
              return;
            case validDocumentTypes.license:
              setcustomSiRowClass("coco-built-license-upload");
              return;
            case validDocumentTypes.agreementAndPostAgreementDocs:
              setcustomSiRowClass(
                "coco-built-agreement-and-post-agreement-upload"
              );
              return;
            case validDocumentTypes.mfaRider:
              setcustomSiRowClass("mfa-rider");
            case validDocumentTypes.waterTesting:
                setcustomSiRowClass("water-testing");
              return;
            default:
              setcustomSiRowClass("");
              return;
          }

        case validStoreTypes.cocoAcquired:
          switch (document) {
            case validDocumentTypes.preAgreementDocs:
              setcustomSiRowClass("coco-acquired-lease-agreement-upload");
              return;
            case validDocumentTypes.license:
              setcustomSiRowClass("coco-acquired-license-upload");
              return;

            case validDocumentTypes.agreementAndPostAgreementDocs:
              setcustomSiRowClass(
                "coco-accquired-agreement-and-post-agreement-upload"
              );
              return;
            case validDocumentTypes.mfaRider:
              setcustomSiRowClass("mfa-rider");
              return;
            case validDocumentTypes.waterTesting:
                setcustomSiRowClass("water-testing");
              return;
            default:
              setcustomSiRowClass("");
              return;
          }

        case validStoreTypes.fofo:
          switch (document) {
            case validDocumentTypes.franchiseAgreement:
              setcustomSiRowClass("fofo-lease-agreement-upload");
              return;
            case validDocumentTypes.subleaseAgreement:
              setcustomSiRowClass("fofo-lease-agreement-upload");
              return;
            case validDocumentTypes.operatingAssignment:
              setcustomSiRowClass("fofo-lease-agreement-upload");
            case validDocumentTypes.license:              
              setcustomSiRowClass("fofo-locense-upload");
              return;
            default:
              setcustomSiRowClass("");
              return;
          }
        default:
          setcustomSiRowClass("");
          return;
      }

    case reportLabelKeys.informational:
      switch (storeType) {
        case validStoreTypes.cocoBuilt:
          switch (document) {
            case validDocumentTypes.preAgreementDocs:
              setcustomSiRowClass("coco-built-lease-agreement-informational");
              return;
            case validDocumentTypes.license:
              setcustomSiRowClass("coco-built-license-informational");
              return;
            case validDocumentTypes.LOI:
              setcustomSiRowClass("coco-built-loi-informational");
              return;
            default:
              setcustomSiRowClass("coco-built-lease-agreement-informational");
              return;
          }

        case validStoreTypes.cocoAcquired:
          switch (document) {
            case validDocumentTypes.preAgreementDocs:
              setcustomSiRowClass(
                "coco-acquired-lease-agreement-informational"
              );
              return;
            case validDocumentTypes.license:
              setcustomSiRowClass("coco-acquired-license-informational");
              return;

            case validDocumentTypes.acquisition:
              setcustomSiRowClass("coco-acquired-acquistion-informational");
              return;
            default:
              setcustomSiRowClass(
                "coco-acquired-lease-agreement-informational"
              );
              return;
          }

        case validStoreTypes.fofo:
          switch (document) {
            case validDocumentTypes.preAgreementDocs:
              setcustomSiRowClass("fofo-lease-agreement-informational");
              return;
            case validDocumentTypes.faAgreement:
              setcustomSiRowClass("fofo-fa-agreement-informational");
              return;
            default:
              setcustomSiRowClass("fofo-lease-agreement-informational");
              return;
          }
        default:
          setcustomSiRowClass("");
          return;
      }
    default:
      setcustomSiRowClass("");
      return;
  }
};
